<template>
  <b-container>
      <h1 class="mt-3">Making a <i>Quick Buck</i></h1>

      <b-row
          v-for="(index) in 2"
          :key="index"
          class="mt-3 justify-content-center"
      >
          <b-col
              v-for="(index) in 2"
              :key="index"
              cols="6"
          >
              <TempAd />
          </b-col>
      </b-row>

      <b-row
          class="text-center mt-3"
          align-v="center"
      >
          <b-col cols="12">
              <b-button
                  class="mt-2"
                  href="#"
                  size="lg"
                  variant="success"
                  @click="backToHome"
              >
                  Back
              </b-button>
          </b-col>
      </b-row>

      <b-row
          class="text-center"
          align-v="center"
      >
          <b-col cols="12">
              <b-button
                  class="mt-4 mb-5"
                  href="mailto:howtomakeaquickbuck@gmail.com"
                  size="sm"
                  target="_blank"
                  variant="success"
              >
                  I Want My Ads on This Page!
              </b-button>
          </b-col>
      </b-row>
  </b-container>
</template>

<script>
    import TempAd from '@/components/TempAd';

    export default {
        name: 'AdPage',
        components: {
            TempAd,
        },
        methods: {
            backToHome() {
                this.$router.push({path: '/'});
            }
        }
    }
</script>

<style lang="scss">
    body {
        color: #21b13c;
    }
</style>
