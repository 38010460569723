<template>
    <BCard
        :header="'Shoulda Coulda Woulda'"
        header-class="header"
        body-class="body"
        class="w-100"
    >
        <BImg
            class="w-100"
            width="400px"
            :src="'https://banedev.s3.us-east-2.amazonaws.com/ad_sense.png'"
        />
    </BCard>
</template>

<script>
    export default {
        name: 'TempAd'
    }
</script>

<style scoped>
    .header {
        background-color: darkgrey !important;
        color: black;
    }

    .body {
        background-color: grey !important;
    }

    .card {
        border-color: black;
        animation: shake 0.6s !important;
        animation-iteration-count: 2.5 !important;
    }

    @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
    }
</style>
